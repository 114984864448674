import styled from "styled-components";
import ProjectComponent from "./Components/ProjectComponent";
import useWindowDimensions from "./Hooks/WindowDimensions";

import DungeonSalvosReduxCover from "../src/Resources/Projects/Dungeon-Salvos-Redux/Cover-640x360.png";
import DungeonSalvosReduxCombat from "../src/Resources/Projects/Dungeon-Salvos-Redux/Combat-640x360.png";
import PhysicsSimulationCover from "../src/Resources/Projects/Physics Simulation/Cover.png";
import UnrealShooterCover from "../src/Resources/Projects/UnrealDemoShooter/Cover.png";
import A2ProjectCover from "../src/Resources/Projects/A2-Project/Cover.png";
import DungeonSalvosCover from "./Resources/Projects/Dungeon-Salvos/Cover.png";
import LudumDare46Cover from "../src/Resources/Projects/LD-46/cover.png";
import DissCover from "../src/Resources/Projects/Dissertation/cover.png";
import DecisionTreePackageCover from "../src/Resources/Projects/decision-tree-package/decision-tree-social-image.png";
import SpaceBoidsCover from "../src/Resources/Projects/SpaceBoids/Thumbnail.png";
import SpaceBoidsEventDiagram from "../src/Resources/Projects/SpaceBoids/EventDiagram.svg";
import PostNavigationSystemThumbnail from "../src/Resources/Projects/PostNavigationSystem/thumbnail.png";
import SickGameThumbnail from "../src/Resources/Projects/Sick-Game/image.png";
import TPSThumbnail from "../src/Resources/Projects/Third-Person-Shooter/thumbnail.png";

import { Theme, theme1 } from "./Theme";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faTwitter,
  faLinkedin,
  faInstagram,
  IconDefinition,
} from "@fortawesome/free-brands-svg-icons";
import { Transition, TransitionStatus } from "react-transition-group";
import { useEffect, useState } from "react";

const SocialIcon = (link: string, icon: IconDefinition, id: string) => {
  return (
    <a href={link} target={"_blank"} rel="noreferrer" id={id} className="icon">
      <FontAwesomeIcon icon={icon} />
    </a>
  );
};

type transitions = {
  [entering in TransitionStatus]: React.CSSProperties;
};

const h1Trans: transitions = {
  entering: {
    opacity: 0,
    marginLeft: "-400px",
  },
  entered: {
    opacity: 1,
    marginLeft: "0",
  },
  exiting: {
    opacity: 0,
    marginLeft: "-400px",
  },
  exited: {
    opacity: 0,
    marginLeft: "-400px",
  },
  unmounted: {},
};

const lin1Trans: transitions = {
  ...h1Trans,
  entered: {
    ...h1Trans.entered,
    marginLeft: "40px",
  },
};

const lin2Trans: transitions = {
  ...lin1Trans,
  entered: {
    ...lin1Trans.entered,
    marginLeft: "80px",
  },
};

const lineTransition: transitions = {
  entering: {
    width: "0",
  },
  entered: {
    width: "500px",
  },
  exiting: {
    width: "0",
  },
  exited: {
    width: "0",
  },
  unmounted: {},
};

const socialMediaIcons: transitions = {
  entering: {
    opacity: 0,
    maxWidth: "0",
  },
  entered: {
    opacity: 1,
    maxWidth: "500px",
  },
  exiting: {
    opacity: 0,
    maxWidth: "0",
  },
  exited: {
    opacity: 0,
    maxWidth: "0",
  },
  unmounted: {},
};

export const Portfolio = () => {
  const [transitionState, setTransitionState] = useState(false);
  const { width } = useWindowDimensions();
  let size: "lg" | "sm";
  if (width <= 600) size = "sm";
  else size = "lg";

  useEffect(() => {
    setTransitionState(true);
  }, []);

  return (
    <Container style={theme1}>
      <HeadingContainer size={size} style={theme1}>
        <Transition in={transitionState} timeout={0}>
          {(state) => {
            return (
              <h1 style={{ transition: "all 2s ease", ...h1Trans[state] }}>
                Portfolio
              </h1>
            );
          }}
        </Transition>
        <Transition in={transitionState} timeout={0}>
          {(state) => {
            return (
              <h2
                id={"line1"}
                style={{ transition: "all 2s ease", ...lin1Trans[state] }}
              >
                Hi! I'm Kieran,
              </h2>
            );
          }}
        </Transition>
        <Transition in={transitionState} timeout={0}>
          {(state) => {
            return (
              <h2
                id={"line2"}
                style={{ transition: "all 2s ease", ...lin2Trans[state] }}
              >
                a Software Engineer in Lincolnshire
              </h2>
            );
          }}
        </Transition>
      </HeadingContainer>
      <Transition in={transitionState} timeout={500}>
        {(state) => {
          lineTransition.entered.width = size === "lg" ? "500px" : "350px";
          return (
            <span
              style={{
                transition: "all 2s ease",
                height: "1px",
                margin: "auto",
                marginTop: "20px",
                marginBottom: "10px",
                backgroundColor: theme1.Border,
                ...lineTransition[state],
              }}
            />
          );
        }}
      </Transition>
      <Transition in={transitionState} timeout={800}>
        {(state) => {
          return (
            <SocialContainer
              size={size}
              style={{
                transition: "all 4s ease",
                ...socialMediaIcons[state],
                ...theme1,
              }}
            >
              {SocialIcon(
                "https://github.com/KieranCoppins",
                faGithub,
                "GitHub"
              )}
              {SocialIcon(
                "https://twitter.com/kcoppins_",
                faTwitter,
                "Twitter"
              )}
              {SocialIcon(
                "https://www.linkedin.com/in/kieranmdcoppins/",
                faLinkedin,
                "LinkedIn"
              )}
              {SocialIcon(
                "https://www.instagram.com/kierancoppins/",
                faInstagram,
                "Instagram"
              )}
            </SocialContainer>
          );
        }}
      </Transition>
      <ProjectsContainer>
        <ProjectComponent
          ProjectName={"Shooter WIP"}
          CoverImages={[TPSThumbnail]}
          VideoLink={"https://youtu.be/rWzMM3_8RBo"}
          ProjectSubText={"A Third Person Shooter Game"}
          size={size}
          ProjectTechnologies={[
            "Unreal Engine",
            "C++",
            "AI",
            "Gameplay",
            "Animation",
            "Game Design",
          ]}
          Content={
            <>
              <p>
                This is a small showcase of the current state of a third person shooter developed in Unreal Engine. The project focuses on third person locomotion and AI design and programming.
                <br />
                <br />
                In this demo, I am using a state machine to power the locomotion of the third person character, using linked animation layers for different weapons. The locomotion system uses IKs to drive left hand positioning, right hand rotation to match the crosshair, feet placement and hip placement for different crouch heights.
                <br />
                <br />

                In an ideal world, with easier access to animations I would not use IKs to drive the hips but instead use additive animations to control the height of the character when crouched / aiming.
                <br />
                <br />

                The AI are driven using Unreal's behaviour tree system. After working on my post system for Unity, I transfered the zone system into Unreal Engine to zone AI. I also use visibility checks on the player to set areas of the map in realtime of where the AI can see. This is used when calculating flanking paths on the player and moving between cover.
                <br />
                <br />

                The AI also have a manager to manage the different roles. These include SHOOTER, AIMER, PUSHER, FLANKER, GRENADIER. When the AI wants to do something, they request the role from the manager and the manager will do aditional checks to see if the AI is allowed to have it. Likewise, the manager may also select the best AI to have a particular role. For example, the manager will select an AI for the grenadier who has a path to throw a grenade.
                <br />
                <br />
              </p>
            </>
          }
        />
        <ProjectComponent
          ProjectName={"Project: Sick-Game"}
          CoverImages={[SickGameThumbnail]}
          GitHubRepo={"https://github.com/KieranCoppins/Sick-Game"}
          VideoLink={"https://www.youtube.com/watch?v=4KtvFRru3WQ&list=PLzHvZswqXt-Yr0DOOIR_00Lj0QXE9TOSX&index=8"}
          ProjectSubText={"A 2D isometric souls-like game"}
          size={size}
          ProjectTechnologies={[
            "Unity3D",
            "Dev Tools",
            "C#",
            "Blender",
            "AI/Gameplay Programming",
            "Jira Suite",
          ]}
          Content={
            <>
              <h3>Overview</h3>
              <p>
                Project Sick-Game is a 2d souls like game with (without my knowing at the time) a similar style and feel to games like Hades.This project was initially started by a friend and I but due to personal reasons my friend was unable to continue. Whilst I developed the game myself I still continued to use project management tools such as Jira and Confluence to keep my progress well documented just in case my friend ever returned. The project is currently not being further developed for reasons I’ll describe below.
              </p>
              <h3>Outcomes</h3>
              <p>
                My main role through the project was to develop AI behavior. As my main interest, I wanted to work on the enemies that the player would face including bosses. During development we intended on bringing on another friend as a game designer. Since they had no programming knowledge I started developing a tool to allow designers to design how AIs should behave; this turned into the Decision Trees for Unity project.
                <br />
                <br />
                Another system I developed for the AI was the Environment Query System (EQS) that is used in Unreal Engine. This read data from the Unity tile map that AI could query. The EQS was made up of rules that could be configured and reused across different queries. This idea was then the basis for the Post Navigation System project.
              </p>
              <h3>Challenges</h3>
              <p>
                One of the main challenges for the project was art. Neither myself nor my friend was an artist. During early development this was fine as we could work on how the gameplay felt. As development progressed further, a lot of the mechanics were animation driven; due to the nature of the game genre and play style. When this challenge originally arose, the project was frozen for a long period of time. One day, I made a breakthrough that I could use prerendering to create the sprites for the characters and enemies. This worked great as I could get models and animations from Mixamo and create sprite sheets. However, this again became an issue when designing bosses as they tend to be unique and therefore need some bespoke art to telegraph their moves. This then led to the project being on pause again.
              </p>
              <h3>The Future</h3>
              <p>
                In the future I would love to pick this project back up. I am very proud of how far it has come and I think it could turn into something special. Perhaps one day I will be able to find an artist that can add their own artistic flair to the project and help work on and design bosses for the player to fight.
                <br />
                <br />
                The challenge of an artist has been a common one in my projects, most of which I haven’t shared. Going forward, I will keep this limitation in mind when planning projects and try to come up with ideas that can showcase what I believe I’m good at and most importantly most enjoy.
              </p>
            </>
          }
        />
        <ProjectComponent
          ProjectName={"Post Navigation System"}
          CoverImages={[PostNavigationSystemThumbnail]}
          GitHubRepo={"https://github.com/KieranCoppins/Post-Navigation-System"}
          ProjectSubText={"A game designer tool for managing NPCs in Unity3D"}
          size={size}
          ProjectTechnologies={[
            "Unity3D",
            "C#",
            "Game Design",
            "Dev Tools",
          ]}
          Content={
            <>
              <h3>Overview</h3>
              <p>
                The primary purpose of this project was to develop a game system with an easy to use and straight forward API.
                I wanted to create indepth and understandable documentation to make the system easy to integrate into any project.
              </p>
              <h3>System</h3>
              <p>
                There are two main parts to the system; posts, which involve post generation and post selection; and zones, which involve
                having placeable zones by a game designer to finer control NPC in their levels. The post generation section can happen at runtime where some
                generic posts are generated around an origin point or you can bake post data into the scene by generating more indepth posts using
                Unity's NavMesh. The NavMesh generation works by efge walking around the navmesh to produce cover points. The post selection system can then load
                and pick from these posts which an NPC could move to.
                <br />
                <br />
                The zone system is a simple system that allows a game designer to place zones in the scene that can be used to control NPC behaviour. Zones are heavily
                based off a Naughty Dog system called hard points. NPCs can be dynamically assigned to zones at runtime where they can run post selection on posts that within
                a given zone. Zones have a minimum and maxmimum number of agents that can be assigned to them. The Zone Manager then monitors the NPCs assigned to zones
                and handles assigning NPCs to zones and moving them as zones need them. Zones also follow a combat vector which describes the flow of combat. This vector
                can be used to produce either a defenesive or offensive flow of combat as it helps set priority of zones in the event that every zone has its minimum agents
                met.
                <br />
                <br />
                More indepth details on how the system works and what the package can do can be found in the readmes and documentation in the git repository.
              </p>
            </>
          }
        />
        <ProjectComponent
          ProjectName={"Space Boids"}
          CoverImages={[SpaceBoidsCover]}
          GitHubRepo={"https://github.com/KieranCoppins/Unity-Sandbox/tree/master/Assets/SpaceBoids"}
          VideoLink={"https://youtu.be/zsr6qhJ6VUA"}
          ProjectSubText={"A Unity Optimisation Investigation"}
          size={size}
          ProjectTechnologies={[
            "Unity3D",
            "C#",
            "Compute Shaders",
            "HLSL",
            "Boids",
          ]}
          Content={
            <>
              <h3>Overview</h3>
              <p>
                For a while I had been thinking about this game where you play as a captain of a large, frigate class, spaceship. And you can command smaller fighter ships that have low level AI that fight eachother. I decided to explore this using my sandbox and then things got... a bit out of hand.
                <br />
                <br />
                I decided to turn this into an investgation into optimisation and pushing the Unity Engine
                to its limits. In the image depicted above, I have 200,000 spaceships fighting eachother,
                all of which are looking for targets, avoiding eachother, trying to aim at their target and
                shooting. I also have another system to handle the projectiles that in this image can handle
                around 70,000 projectiles. This was all running in real-time at around 40 frames-per-second.
              </p>
              <h3>Design</h3>
              <p>The majority of the design for this roject is outlined in the README of the git repository.
                However to briefly outline some core parts of the design, I had to create 2 different systems:
                the boids system, which handled the control of the boids and their spatial partitioning; and the
                projectile system, which handled the creation and destruction of the projectiles. Here is a
                diagram on how these systems worked together:
              </p>
              <StyledContentImageContainer src={SpaceBoidsEventDiagram} alt={"Event Diagram for Compute Buffers"} />
            </>
          }
        />
        <ProjectComponent
          ProjectName={"Decision Tree Visual Editor"}
          CoverImages={[DecisionTreePackageCover]}
          GitHubRepo={"https://github.com/KieranCoppins/Decision-Tree-Package"}
          VideoLink={
            "https://www.youtube.com/watch?v=F6SGtkVm7Vo&feature=youtu.be"
          }
          ProjectSubText={"An AI development tool for Unity3D"}
          size={size}
          ProjectTechnologies={[
            "Unity3D",
            "AI",
            "Decision Trees",
            "C#",
            "Dev Tools",
          ]}
          Content={
            <>
              <h3>Overview</h3>
              <p>
                Recently I've been working on a game in my spare time and spent
                quite a lot of time in developing a visual editor for creating
                decision trees for my AI. After a lot of development time I
                decided it would be a good idea for myself to turn it into a
                package that I can import into future projects. I am quite
                surprised as to how well I managed to turn it into a package and
                I believe it can be plugged into any project. It felt rude to
                just keep this to myself, so here I am releasing it for free on
                GitHub, fully open source. I hope this can help some of you and
                you enjoy using my tool!
              </p>
              <h3>Features</h3>
              <p>
                This package contains a visual editor to create and modify
                decision trees. The package is fully flexible allowing users to
                create nodes to perform whatever functions they like. It
                contains a couple sample assets that show off exactly how to
                implement this tool into your own projects
              </p>
              <p>
                Each node contains a description & title that are procedurally
                generated based on descriptions of other nodes to give a rough
                description of what that node does; all of which update in
                realtime.
              </p>
              <p>
                Nodes can have an unlimited number of inputs that can be
                specified by the programmer. It allows for function nodes to
                interface with other nodes. There are also logic gate nodes such
                as AND that can do boolean logic on two boolean function nodes.
              </p>
            </>
          }
        />
        <ProjectComponent
          ProjectName={"Dungeon Salvos Redux"}
          CoverImages={[DungeonSalvosReduxCover, DungeonSalvosReduxCombat]}
          GitHubRepo={
            "https://github.com/KieranCoppins/Dungeon-Salvos-Redux/releases/latest"
          }
          ProjectSubText={"A Unity3D dungeon crawler"}
          size={size}
          ProjectTechnologies={[
            "Unity3D",
            "Dijkstra's Algorithm",
            "C#",
            "Procedural Generation",
            "Blender",
          ]}
          Content={
            <>
              <h3>Overview</h3>
              <p>
                <i>Dungeon Salvos Redux</i> is a remake of my original game{" "}
                <i>Dungeon Salvos</i> that was written in Python. I wanted to
                recreate my first game inside a game engine to add 3D graphics.
                Unlike its original, which featured ASCII graphics, this remake
                contains modular assets that I modelled using <b>Blender</b>.
                The remake also had to be translated from its original language,
                into <b>C#</b>; the language of <b>Unity3D</b>.
              </p>
              <h3>Design</h3>
              <p>
                With a 3D engine, the way the player interacted with the game
                had to be changed. Compared to the original which used keyboard
                inputs to move and simultaneously end turns in a turn-based
                combat game. I wanted to turn the remake into a more real time
                game which utilised the mouse in controlling. A large portion of
                the mechanics of this game were inspired by popular MMO{" "}
                <i>Runescape</i>. The player can move by clicking tiles to move
                to and the character would path-find to the target tile. Combat
                worked off a tick system, again similarly to <i>Runescape</i>,
                where the player and enemies would attack at consistent time
                intervals.
              </p>
              <h3>Implementation</h3>
              <p>
                The first challenge was keeping the procedurally generated world
                whilst also being able to path find to different tiles. This was
                achieved by procedurally generating a graph data structure that
                contained information on what tiles within the map are
                connected. For the player and enemies to path find through the
                environment, <b>Dijkstra’s</b> algorithm was used to produce the
                shortest path to the target.
              </p>
              <p>
                Another challenge was making sure the graphics lined up
                correctly with the procedurally generated world. A separate
                algorithm was written which would check neighbouring tiles and
                decide which asset would be best to load in its position. Since
                I made sure the assets were modular, it allowed for spacing
                between assets to be consistent which made placing them easier.
              </p>
            </>
          }
        />
        <ProjectComponent
          ProjectName={"Physics Simulation"}
          CoverImages={[PhysicsSimulationCover]}
          GitHubRepo={
            "https://github.com/KieranCoppins/Physic-Simulation-Assignment/releases/latest"
          }
          VideoLink={"https://youtu.be/xuHvUiZ74KU"}
          ProjectSubText={"Using NVIDIA Physx & OpenGL"}
          size={size}
          ProjectTechnologies={[
            "C++",
            "NVIDIA PhysX",
            "OpenGL",
            "Particle Physics",
          ]}
          Content={
            <>
              <h3>Overview</h3>
              <p>
                During my third year at University, I studied physics
                simulation. In this module we had to utilise{" "}
                <b>NVIDIA PhysX </b> middleware to create a domino show. The
                physics engine was then linked to <b>OpenGL</b> to handle all
                the graphics rendering, which was mostly given to us by our
                lecturer, but I made some adjustments. My assignment shows the
                use of colliders, joints, motors, trigger volumes, cloth, and{" "}
                <b>particle physics</b>. For this assignment I received a first
                for the entire module.
              </p>
              <h3>Design</h3>
              <p>
                For our assignment, our lecturer wanted us to make sure that the
                proportions and weighting of the dominos were accurate. This
                required us to go out and research into domino dimensions,
                weights, and friction coefficients; this ensured that the
                dominos behaved as accurately as possible. Our lecturer also
                wanted our domino show to start in an interesting way through
                user input. To do this I created a <i>Newton's Cradle</i>, a
                great device to show the effects of the{" "}
                <i>conservation of momentum</i>, which the user was able to
                apply force to start the domino show. As with the dominos, the
                weight and density of each ball had to be researched to ensure
                the effects were authentic.
              </p>
              <h3>Implementation</h3>
              <p>
                Since we had to manually place the dominos, I made some
                functions that helped design the scene without needing to keep
                creating separate loops to place the dominos where I want. This
                helped massively speed up development of the scene as just a
                couple of functions were needed to be called. These functions
                were fully dynamic and saved their ending position in 3D space
                that allowed for future functions to use to generate more domino
                shapes.
              </p>
              <p>
                The particle effect system was the cherry on top for this
                assignment. As an optional extra, I took it upon myself to
                create a large particle explosion when the domino show had
                finished. Since the <i>OpenGL</i> provided to us didn’t support
                particles, I had to program my own way of rendering them. Due to
                the large quantity of particles, I had to make sure the mesh
                that was generated from them were cheap to draw. This is where I
                decided to use a simple billboard effect on a quad to give this
                confetti like effect.
              </p>
            </>
          }
        />
        <ProjectComponent
          ProjectName={"Unreal Shooter Demo"}
          CoverImages={[UnrealShooterCover]}
          GitHubRepo={
            "https://github.com/KieranCoppins/Shooter-Demo/releases/latest"
          }
          ProjectSubText={"A third person shooter demo in Unreal Engine 5"}
          size={size}
          ProjectTechnologies={[
            "C++",
            "Unreal Engine 5",
            "Behaviour Trees",
            "Blackboards",
            "EQS",
          ]}
          Content={
            <>
              <h3>Overview</h3>
              <p>
                A big love and interest of mine if video game <b>A.I</b>; more
                specifically smart and realistic human A.I. This project started
                when I was researched into the human A.I used in The Last of Us.
                A paper written by <i>Travis McIntosh</i> outlined the details
                into how they developed intelligent, and most importantly, fun
                A.I. I took it upon myself, as a fan of the series, to develop
                my own version using a similar structure. I found this was a
                good way to practice my <b>C++</b> skills as well as produce
                something I was interested in. Fans of the series may notice
                that the blocked-out level I used to test these A.I is a close
                representation of the shipping yard showcased early in the first
                game. This project is still a work in progress and only contains
                enemies that move into cover and try flank the player.
              </p>
              <h3>Design</h3>
              <p>
                Since I was using <i>McIntosh’s</i> paper as the core part of
                the development of this system, that left a lot of the designing
                out. Although the paper doesn’t outline exactly how different
                systems worked, it was up to me to interpret them and try my
                best to recreate it. The bulk of the planning was taking the
                paper and creating some diagrams in how I want the A.I behave
                and how I pictured them behaving from both <i>McIntosh’s</i>{" "}
                paper and through my playthrough of the game. Once I had the
                pieces all together on what will happen and when, all was left
                was implementing the systems.
              </p>
              <h3>Implementation</h3>
              <p>
                Since this project uses <b>Unreal Engine 5</b>, I made use of
                the <b>Behaviour Tree</b> and <b>Blackboard</b> system built
                into the engine. This allowed for some quick development of some
                of the easier functionalities of the A.I. I also tried to make a
                conscious decision to make sure the A.I didn’t cheat too much.
                This meant that the A.I never had a direct source of the
                player’s location; only last known locations were saved. This
                meant that if the player had broken line of sight, there is a
                chance they could slip away.
              </p>
              <p>
                To get relevant locations in the 3D world, I utilised Unreal’s{" "}
                <b>Environment Query System</b> (EQS). This system creates a
                grid around a location of select points and applies a series of
                rules to each point. Depending on the outcome of these rules,
                scored each point in suitability and returned the most suitable
                point for the A.I to move to. An example of this would be
                selecting the best cover location.
              </p>
              <p></p>
            </>
          }
        />
        <ProjectComponent
          ProjectName={
            "Exploring User Experience using Mixed Reality in Controlling Multiple Robot Agents"
          }
          CoverImages={[DissCover]}
          GitHubRepo={"https://github.com/KieranCoppins/Project-Artefact"}
          VideoLink={"https://youtu.be/Fh0hqW3M6f4"}
          FileLink={
            "Exploring User Experience using Mixed Reality in Controlling Multiple Robot Agents.pdf"
          }
          ProjectSubText={
            "My dissertation for my Bachelors in Computer Science"
          }
          size={size}
          ProjectTechnologies={[
            "Unity3D",
            "C#",
            "Point Clouds",
            "Mixed Reality",
            "Microsoft HoloLens",
            "Microsoft Mixed Realiy Toolkit",
            "Unity NavMesh",
            "A* pathfinding",
          ]}
          Content={
            <>
              <p>
                The development of this project was very iterative. Multiple
                ideas were produced and thrown out even right up until about 50%
                of the overall timeline of the project. I was always interested
                in doing a <b>mixed reality</b> application. Ideas went from AR
                windscreens for cars, software for car mechanics to identify
                parts of the car and highlight them in AR. But ultimately
                holograms with the HoloLens lead to the creation of this
                project. Everything about this project can be read in my
                dissertation which can be downloaded in the file link above but
                for now I’ll leave the abstract below:
              </p>
              <p>
                With robots paving the way of the future and as man explores
                beyond our world or deep within our own oceans (Cruz, 2017),
                robots will be needed to reach places that man cannot. This
                project provides a proof of concept in designing an augmented
                reality (AR) head mounted display (HMD) interface using the
                Microsoft HoloLens to control multiple robots in scanning and
                exploring unreachable environments. With The National
                Aeronautics and Space Administration (NASA) landing rovers on
                Mars (NASA, 2021), it is becoming clearer that unmanned robots
                are the way to future exploration (Sanguino, 2017). Although
                there are systems that exist that allow for automated
                exploration of unreachable areas (Verbiest et al., 2016), this
                project designs a system that gives the user control on where
                robots should navigate to, to control multiple robots at once,
                and view live point cloud data captured by these robots. This
                project analyses and evaluates the user experience of the
                designed interface using semi-structured interviews and the NASA
                Task Load Index (NASA TLX). The goal of the project is to see if
                an interface to control multiple robots in AR is viable and to
                measure the user experience.
              </p>
            </>
          }
        />
        <ProjectComponent
          ProjectName={"A2 Project"}
          CoverImages={[A2ProjectCover]}
          GitHubRepo={
            "https://github.com/KieranCoppins/A2-Project/releases/latest"
          }
          ProjectSubText={"A pathfinding investigation in Python"}
          size={size}
          ProjectTechnologies={[
            "Python",
            "Dijkstra's Algorithm",
            "A* Algorithm",
            "TKinter",
          ]}
          Content={
            <>
              <h3>Overview</h3>
              <p>
                This investigation was part of my project for my A-Levels. It
                investigates different A.I pathfinding algorithms in different
                tile map environments and compares the path length of each
                algorithm to determine which algorithms are more suited for
                different environments. The whole project is coded in{" "}
                <b>Python 3</b>
                (the language used for the course).
              </p>
              <h3>Design</h3>
              <p>
                The two core pathfinding algorithms were <b>Dijkstra’s</b> and{" "}
                <b>A*</b>: as the most used pathfinding algorithms around today.
                For the tile maps I used a dungeon crawler like map, generated
                using an algorithm of my own design that is present in{" "}
                <i>Dungeon Salvos</i>; and a cave-like map, which was generated
                using a <b>Cellular Automata</b> ruleset. This gave a good
                balance between a closely confined map with very little room for
                choice and a large open map with fewer obstacles that allowed
                the pathfinding algorithm to roam free.
              </p>
              <h3>Implementation</h3>
              <p>
                The front-end of the application was powered using a Python
                library called <b>TKinter</b>. This allowed me to create popups
                and text fields for the user to enter the data they required.
                Pathfinding algorithms were programmed using pseudocode provided
                online. They were then modularised so that they may be used
                again in the future for my own personal projects; the same
                treatment was applied to the map generations. For the
                pathfinding algorithms to work, the map had to be translated
                into a graph data structure. This was also modularised inside
                the graph script that contained the code for the data structure.
                Dungeon-crawler-like maps were generated using an algorithm of
                my own creation; the details of which are discussed in{" "}
                <i>Dungeon Salvos</i>. As for the cave-like maps, they were
                generated first by randomly placing wall tiles and floor tiles.
                Smoothing was then applied were each tile had a simple rule to
                follow:
              </p>
              <div>
                <ul>
                  <li>
                    <p>
                      If a tile is a <b>floor</b>
                    </p>
                    <ul>
                      <li>
                        <p>
                          If they have less than <i>x</i> <b>floor</b> tile
                          neighbours, become a <b>wall</b>
                        </p>
                      </li>
                      <li>
                        <p>
                          Otherwise stay a <b>floor</b> tile
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      If a tile is a <b>wall</b>
                    </p>
                    <ul>
                      <li>
                        <p>
                          If they have more than <i>x</i> <b>floor</b> tile
                          neighbours, become a <b>floor</b>
                        </p>
                      </li>
                      <li>
                        <p>
                          Otherwise stay a <b>wall</b> tile
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <p>
                These values were provided by the user to get different map
                generations. This same algorithm can also be used to create
                islands by simply inverting the walls and floors. The user could
                also specify how often these rules run.
              </p>
            </>
          }
        />
        <ProjectComponent
          ProjectName={"Dungeon Salvos"}
          CoverImages={[DungeonSalvosCover]}
          GitHubRepo={
            "https://github.com/KieranCoppins/Dungeon-Salvos/releases/latest"
          }
          ProjectSubText={"My first finished game!"}
          size={size}
          ProjectTechnologies={[
            "Python",
            "TKinter",
            "Procedural generation",
            "Dijkstra's Algorithm",
          ]}
          Content={
            <>
              <h3>Overview</h3>
              <p>
                Made in 2018, <i>Dungeon Salvos</i> was the first game I ever
                completed. It was written in pure <b>Python 3</b> and had
                multiple systems such as combat, A.I, inventory, and procedural
                map generation. All these systems I designed and implemented
                myself. For my first game, I’m extremely proud and will always
                hold a special place in my heart – even if from a coding
                standpoint it isn’t the most impressive game I’ve made.
              </p>
              <h3>Design</h3>
              <p>
                Designing this game was just built on top of itself as I felt
                new systems were needed to make a more engaging game. I always
                wanted to develop it with procedural generation in mind to make
                the game have more replay value. To achieve this,{" "}
                <b>map generation</b> had to be procedural. Once I had a
                character that I could move around this world, I had to
                introduce an A.I system. The <b>A.I</b> would have to move
                towards the player when in range and attack the player. With a
                procedurally generated map, the placement of the A.I also had to
                be procedural.
              </p>
              <p>
                The <b>inventory</b> system was introduced as I felt the game
                needed some items to make it more interactive and to promote the
                need for the player to explore the environment rather than just
                move onto the next level. Items that can be found range from
                different levels of equipment and consumable health potions.
              </p>
              <p>
                After a few playtests, it felt the game got quite boring after
                the first or second level as it was too repetitive and once you
                had the best items you were unstoppable and stopped being a
                challenge. This is where I added scaling to both items, enemy
                damage, and enemy quantity. This made each level more
                challenging than the last and required the player to go and find
                better loot that spawned in each level.
              </p>
              <h3>Implementation</h3>
              <p>
                The <b>map generation</b> is an algorithm of my own design.
                First the map would be populated with wall tiles, which the
                player cannot move through. Next, a random number between a
                threshold of rooms were generated for the level. Each room was
                its own object that stored information such as its size, centre,
                door coordinates, and if the room had been connected to the map.
                When creating a room, it would make sure that the room could be
                created, and would try and recreate the room if, for example,
                the room was to grow out of the maps bounds. Once the room was
                valid to be made, it was also checked against all over rooms in
                the map to see if they intersect, if they do intersect, a new
                room was created. Next was to join the rooms by their door
                coordinates. This was achieved by a naïve approach that would
                simply generate a corridor along the x and then the y to match
                up. This could be improved by having shorter corridors using a
                pathfinding algorithm to make more interesting and direct
                corridors. Once the map is generated a staircase was added
                randomly to the level that would take the player to the next
                level.
              </p>
              <p>
                <b>A.I</b> was made very simply. All they had to do was check
                each turn if the player was within an attack radius. If they
                were the A.I would move towards the player. If the A.I are about
                to move onto the player’s tile, this is when they would “attack”
                and deal damage to the player. Damage calculations were
                determined by taking the defence rating of the player away from
                the attack damage of the enemy. The resultant hit would be
                applied to the player’s health. For the player’s damage, a
                random number between the players min and max damage would be
                applied to the enemy.
              </p>
              <p>
                The <b>inventory</b> system was a simple design that meant that
                items that were placed in the world were copied into a list of
                items that the player had. The items could range from armour to
                weapons to consumables. These were all displayed in their own
                window that the player could manoeuvre to equip or consume the
                item. To prevent players hording items at the start of the game,
                I introduced an inventory capacity.
              </p>
            </>
          }
        />
        <ProjectComponent
          ProjectName={"Darryl in Distress"}
          CoverImages={[LudumDare46Cover]}
          GitHubRepo={"https://github.com/KieranCoppins/LD46/releases/latest"}
          ProjectSubText={"My Ludum Dare 46 submission"}
          size={size}
          ProjectTechnologies={["Unity3D", "Pyxel Edit", "C#"]}
          Content={
            <>
              <h3>Overview</h3>
              <p>
                <i>Darryl in Distress</i> was a game I made for the Ludum Dare
                46 game jam. The game jam challenges participants to create a
                game within 48 hours against a given theme. For the{" "}
                <i>
                  46
                  <sup>th</sup> Ludum Dare
                </i>
                , the theme was <i>Keep It Alive</i>. I designed and created
                this game for the game jam in <b>Unity3D</b> and created the art
                and animations using <b>Pyxel Edit</b>.{" "}
                <i>Darryl in Distress</i> came 657 <sup>th</sup> overall out of
                4900 total competition entries (top 13% of entries).
              </p>
              <h3>Design</h3>
              <p>
                To fit the theme: <i>Keep It Alive</i>, I wanted to develop a
                game where the player had to prevent their character from dying.
                This came about after I watched a recent documentary that showed
                how baby turtles had to make a rush from the beach into the
                water once they are born. To replicate this, I created a game
                where the player’s character (Darryl, a baby turtle) is curious
                about their world and wants to go towards things that interest
                him but are dangerous for him. A simple button mashing mechanic
                was made to ensure that Darryl doesn’t move towards the danger
                and instead goes towards the ocean.
              </p>
              <h3>Implementation</h3>
              <p>
                Due to the speed in which the game had to be made, the game is
                very simple. As the Darryl moves towards the coast, at random
                intervals he will be interested in something dangerous and move
                towards them. This is when the player will be prompted to button
                mash to stop Darryl from moving towards the danger and carry on
                towards the coast.
              </p>
            </>
          }
        />
      </ProjectsContainer>
    </Container>
  );
};

const ProjectsContainer = styled.section`
  display: flex;
  text-align: left;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  padding: 2rem;
  max-width: 1500px;
  margin: auto;
`;

const Container = styled.div<{ style: Theme }>`
  display: flex;
  flex-flow: column;
  text-align: center;
  background-color: ${(props) => props.style.Background};
  color: ${(props) => props.style.Body};

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const HeadingContainer = styled.header<{ size: "lg" | "sm"; style: Theme }>`
  padding-top: ${(props) => (props.size === "lg" ? "200px" : "50px")};
  text-align: left;
  margin: auto;
  color: ${(props) => props.style.Header};

  h1 {
    font-size: ${(props) => (props.size === "lg" ? "100px" : "50px")};
    font-weight: 600;
    margin: 0;
    color: ${(props) => props.style.Header};
  }

  h2 {
    font-size: ${(props) => (props.size === "lg" ? "50px" : "25px")};
    font-weight: 300;
    margin: 0;
    color: ${(props) => props.style.Header};
  }

  #line2 {
    font-size: ${(props) => (props.size === "lg" ? "36px" : "18px")};
  }
`;

const SocialContainer = styled.section<{ size: "lg" | "sm"; style: Theme }>`
  display: flex;
  justify-content: center;
  margin: auto;

  .icon {
    margin: 0 20px 0 20px;
    width: 100%;
    max-width: ${(props) => (props.size === "lg" ? "48px" : "40px")};
    height: ${(props) => (props.size === "lg" ? "48px" : "40px")};
    color: ${(props) => props.style.PrimaryColor};
  }

  .icon svg {
    width: inherit;
    height: inherit;
  }

  .icon:hover {
    color: ${(props) => props.style.TertuaryColor};
  }
`;

const StyledContentImageContainer = styled.img`
  width: 100%;
  `;
